import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../../components/images/imageCoaching.js'
import ImageDevelopement from '../../components/images/imageDevelopement.js'
import ImageClimat from '../../components/images/imageClimat.js'
import ImageChange from '../../components/images/imageChange.js'
import ImageConflit from '../../components/images/imageConflit.js'
import ImageLeft7 from '../../components/images/imageLeft7.js'

const Equipes = () => (
  <Layout>
    <SEO title="Développement des compétences pour les leaders | Développement | Gestion Mieux-Être" />
    <PageHeader
        header="Développement des compétences pour les leaders"
        text="Nous sommes complices des leaders qui souhaitent accroître leur impact et influence.  Nous offrons une gamme de solutions de développement sur mesure visant à accroître l'efficacité de leurs dynamiques interpersonnelles."
        quote="Changez par vous-même avant de devoir le faire par obligation  <span>- Jack Welch</span>"
        background="developpement" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">

        <p class="service-presentation2">Voici ici quelques exemples d'activités de développement pour les leaders (Formation – Conférences):</p>
        <div className="flex-container pad2">

            <div className="half">
                <ul className="success">
                    <li>Le passage d’expert à leader d’équipe : préparez votre transition avec succès </li>
                    <li>Augmenter votre influence, c’est possible!</li>
                    <li>La gestion des comportements difficiles</li>
                    <li>Transigez avec des parties prenantes « toxiques » </li>
                    <li>Placez l’éthique au centre de votre pratique professionnelle </li>
                    <li>Votre projet crée de la turbulence : pilotez ces changements avec assurance</li>
                </ul>
            </div>
            <div className="half"><ImageLeft7 /></div>
        </div>
        <p class="service-presentation2">Le perfectionnement n'est-il pas une occasion privilégiée afin d'envisager vos défis dans de nouvelles perspectives?  </p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>

    </div>
  </Layout>
)

export default Equipes
